.home {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  padding: 70px 0;
  text-align: center;
}

h1 {
  text-align: center;
  color: rgb(26 33 50);
}

.desc {
  font-size: 1.3rem;
  font-weight: 400;
}
