
.navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: 0 1px 5px 0 black;
  background-color: rgb(26 33 50);
  padding-left: 15px;
  align-items: center;
}

ul {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  list-style: none;
  gap: 10px;
  align-items: center;
  margin: 10px;
  font-size: 20px;
}

.navbar li {
  padding: 0 10px;
  border-right: 1px solid white;
  height: min-content;
  color: white;
}

.navbar li:last-child {
  padding: 0 10px;
  border: 0;
}

.navbar li a {
  color: white;
}
