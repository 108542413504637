* {
  overflow-x: hidden;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 1%;
}

.calculator {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 80px;
  padding: 10px;
  background-color: rgb(26 33 50);
  width: 40%;
  height: 50%;
  border-radius: 30px;
  grid-row-gap: 5px;
  grid-column-gap: 5px;
}

button {
  font-size: 1.3em;
  font-weight: bold;
  width: 95%;
  border: none;
  background-color: rgb(221, 221, 221);
  border-radius: 10px;
  cursor: pointer;
  height: 90%;
}

button:hover {
  background-color: rgb(177, 174, 174);
  transition: 0.25s ease-in;
}

.display-section {
  grid-column: 1/5;
  text-align: right;
  font-size: 1.5em;
  padding: 20px 20px;
  background-color: rgb(67, 67, 67);
  color: white;
  border-radius: 10px;
  border: none;
}

.zero {
  grid-column: 1/3;
}

.operator {
  background-color: rgb(216, 140, 0);
}

.operator:hover {
  background-color: rgb(176, 116, 4);
}
